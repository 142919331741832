<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input v-model="nodeToBind.node_name"></el-input>
      </el-form-item>

      <el-form-item
        :label="__('Transfer to phone number')"
        prop="bridge_transfer_node.data.property_rules.data.transfer_phone_number"
        class="is-required"
      >
        <expression-input
          v-model="transferPhoneNumber"
          :complex-variables="complexVariables"
          :placeholder="
            __('Enter transfer phone number using the expression builder')
          "
          @edit-input="displayTransferPhoneNumberExpressionBuilderModal = true"
        ></expression-input>
      </el-form-item>
      <el-form-item
        :label="__('ANI')"
        prop="bridge_transfer_node.data.property_rules.data.ani"
      >
        <!-- eslint-disable-next-line -->
        {{ __("Please note: ANI modification feature is network dependent. Please consult your service provider") }}
        <expression-input
          v-model="ani"
          :complex-variables="complexVariables"
          :placeholder="__('Enter ANI using the expression builder')"
          @edit-input="displayAniExpressionBuilderModal = true"
        ></expression-input>
      </el-form-item>
      <el-form-item
        :label="__('Display Name')"
        prop="bridge_transfer_node.data.property_rules.data.display_name"
      >
        <expression-input
          v-model="displayName"
          :complex-variables="complexVariables"
          :placeholder="__('Enter display name using the expression builder')"
          @edit-input="displayNameExpressionBuilderModal = true"
        ></expression-input>
      </el-form-item>
      <el-form-item
        :label="__('Transfer timeout (seconds)')"
        prop="bridge_transfer_node.data.transfer_timeout"
      >
        <el-select
          v-model="nodeToBind.bridge_transfer_node.data.transfer_timeout"
          style="width: 100%;"
          default-first-option
          filterable
          :placeholder="__('Platform default')"
        >
          <el-option
            v-for="(item, index) in transferTimeoutLimit"
            :label="item"
            :value="item"
            :key="index"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="__('No answer / busy event handler')"
        prop="bridge_transfer_node.data.no_answer_busy_canvas_id"
        class="is-required"
      >
        <create-or-select-canvas
          :event-handler-canvas="noAnswerBusyEventHandlerCanvas"
          :place-holder="__('Select or create a no match event handler canvas')"
          @change="setNoAnswerBusyEventHandlerCanvas($event)"
        ></create-or-select-canvas>
      </el-form-item>
      <el-form-item
        :label="__('On-hold music')"
        prop="bridge_transfer_node.data.music_on_hold_file_id"
      >
        <wav-file-uploader
          :file-name="attachment_file_obj.file_name"
          :file-url="attachment_file_obj.file_url"
          folder="files"
          @on-success="handleSuccess"
          @on-delete="handleDelete"
          @on-progress="isLoading = true"
          @on-error="handleError"
          v-loading="isLoadingAttachment"
        >
        </wav-file-uploader>
      </el-form-item>
    </el-form>
    <expression-builder-dialog
      v-if="displayTransferPhoneNumberExpressionBuilderModal"
      v-model="transferPhoneNumber"
      :show-expression-builder="
        displayTransferPhoneNumberExpressionBuilderModal
      "
      @input="handleCancelTransferNumberExpressionBuilder"
      @cancel="handleCancelTransferNumberExpressionBuilder"
      @close="handleCancelTransferNumberExpressionBuilder"
    />

    <expression-builder-dialog
      v-if="displayAniExpressionBuilderModal"
      v-model="ani"
      :show-expression-builder="displayAniExpressionBuilderModal"
      @input="handleCancelAniExpressionBuilder"
      @cancel="handleCancelAniExpressionBuilder"
      @close="handleCancelAniExpressionBuilder"
    />
    <expression-builder-dialog
      v-if="displayNameExpressionBuilderModal"
      v-model="displayName"
      :show-expression-builder="displayNameExpressionBuilderModal"
      @input="handleCancelDisplayNameExpressionBuilder"
      @cancel="handleCancelDisplayNameExpressionBuilder"
      @close="handleCancelDisplayNameExpressionBuilder"
    />
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import { NODE_TYPES } from "@/constants/nodes";
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";
import { numberOrVariable } from "@/utils/regex";
import WavFileUploader from "@/components/uploaders/types/WavFileUploader";
import { removeFiles } from "@/api/services";
import ExpressionInput from "@/views/build/callflow/components/expression-builder/ExpressionInput";
import ExpressionBuilderDialog from "@/views/build/callflow/components/expression-builder/ExpressionBuilderDialog";
import CreateOrSelectCanvas from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelectCanvas";
import { eventHandlerNameValidation } from "@/utils/formValidationRules";
import renewSignedURL from "@/mixins/RenewSignedURL.vue";

const eventHandlerCanvasInitialize = {
  canvas_name: "",
  canvas_id: -1,
  msg: ""
};
const initBridgeTransferNode = {
  node_type: NODE_TYPES.BRIDGE_TRANSFER.NODE_TYPE,
  bridge_transfer_node: {
    data: {
      property_rules: {
        data: {
          transfer_phone_number: "",
          display_name: "",
          ani: ""
        }
      },
      transfer_timeout: null,
      no_answer_busy_canvas_id: _.clone(eventHandlerCanvasInitialize),
      music_on_hold_file_id: null
    }
  }
};

const attachmentFileObjInitialize = {
  file_id: -1,
  file_name: "",
  file_path: "",
  file_type: "audio/wav",
  file_url: "",
  unique_code: "",
  ac_id: 0
};

export default {
  name: "BridgeTransferNode",
  components: {
    WavFileUploader,
    ExpressionBuilderDialog,
    ExpressionInput,
    CreateOrSelectCanvas
  },
  mixins: [BaseNode, renewSignedURL],

  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (!value) {
        callback(__("Transfer phone number is required"));
      } else if (_.isEmpty(JSON.parse(value))) {
        callback(__("Transfer phone number is required"));
      } else {
        callback();
      }
    };

    const validateNoAnswerBusyCanvasId = (rule, value, callback) => {
      if (!value) {
        callback(__("Event handler is required"));
      } else if (value.canvas_id === -1 && !value.canvas_name) {
        callback(__("Event handler is required"));
      } else {
        eventHandlerNameValidation(value, callback, this, "No answer / busy");
      }
    };

    return {
      rules: {
        "bridge_transfer_node.data.property_rules.data.transfer_phone_number": [
          {
            validator: validatePhoneNumber,
            trigger: "blur"
          }
        ],
        "bridge_transfer_node.data.no_answer_busy_canvas_id": [
          {
            validator: validateNoAnswerBusyCanvasId,
            trigger: "blur"
          }
        ]
      },
      variableRulesBkup: [],
      newVariableForAudioURL: 0,
      newVariableForAudioVar: 0,
      allowedRegex: numberOrVariable,
      transferTimeoutLimit: _.range(1, 121),
      file_url: "",
      file_name: "",
      attachment_file_obj: _.cloneDeep(attachmentFileObjInitialize),
      isLoading: false,
      isLoadingAttachment: false,
      newFileUploaded: false,
      createOrUpdateAttachmentStatus: "createFile",
      displayTransferPhoneNumberExpressionBuilderModal: false,
      displayAniExpressionBuilderModal: false,
      displayNameExpressionBuilderModal: false
    };
  },
  computed: {
    ...mapGetters("canvas", {
      getEventHandlerCanvasList: "getEventHandlerCanvasList"
    }),
    // ...mapState("files", {
    //   isFileLoading: state => state.isLoading
    // }),
    currentSelection() {
      return row => {
        const { variable_id, variable_name } = row;
        return variable_id === -1 ? variable_name : variable_id;
      };
    },
    getFileAttachmentAPIMethod() {
      return this.attachment_file_obj.file_id === -1
        ? "createFile"
        : "updateFile";
    },
    noAnswerBusyEventHandlerCanvas() {
      return this.nodeToBind.bridge_transfer_node.data.no_answer_busy_canvas_id;
    },
    getUniqueFolder: {
      get: function() {
        return this.contentForm.unique_code;
      },
      // setter
      set: function(newValue) {
        this.contentForm.unique_code = newValue;
      }
    },
    transferPhoneNumber: {
      get() {
        return (
          this.nodeToBind.bridge_transfer_node.data.property_rules.data
            .transfer_phone_number || "{}"
        );
      },
      set({ expression }) {
        this.$set(
          this.nodeToBind.bridge_transfer_node.data.property_rules.data,
          "transfer_phone_number",
          expression
        );
        this.$refs.nodeForm.validateField(
          "bridge_transfer_node.data.property_rules.data.transfer_phone_number"
        );
      }
    },

    ani: {
      get() {
        return (
          this.nodeToBind.bridge_transfer_node.data.property_rules.data.ani ||
          "{}"
        );
      },
      set({ expression }) {
        this.$set(
          this.nodeToBind.bridge_transfer_node.data.property_rules.data,
          "ani",
          expression
        );
      }
    },

    displayName: {
      get() {
        return (
          this.nodeToBind.bridge_transfer_node.data.property_rules.data
            .display_name || "{}"
        );
      },
      set({ expression }) {
        this.$set(
          this.nodeToBind.bridge_transfer_node.data.property_rules.data,
          "display_name",
          expression
        );
      }
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.bridge_transfer_node)
    ) {
      this.initializeBridgeTransferNodeData();
    } else {
      if (
        this.nodeToBind.bridge_transfer_node.data.no_answer_busy_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.bridge_transfer_node.data,
          "no_answer_busy_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noAnswerBusyEventHandlerCanvas
          })
        );
      }
    }
    this.setAttachmentFileValuesToData();
  },
  methods: {
    ...mapActions("files", {
      createFile: "createFile",
      updateFile: "updateFile",
      deleteFile: "deleteFile"
    }),
    initializeBridgeTransferNodeData() {
      this.nodeToBind = Object.assign(
        {},
        this.nodeToBind,
        _.cloneDeep(initBridgeTransferNode)
      );
    },

    async setAttachmentFileValuesToData() {
      this.isLoadingAttachment = true;
      if (
        _.has(
          this.nodeToBind.bridge_transfer_node.data,
          "attachment_file.data.file_id"
        )
      ) {
        let attachment_file = this.nodeToBind.bridge_transfer_node.data
          .attachment_file.data;
        this.attachment_file_obj.file_id = _.get(
          attachment_file,
          "file_id",
          -1
        );
        this.attachment_file_obj.file_path = attachment_file.file_path;
        this.attachment_file_obj.file_url = attachment_file.file_url;
        this.attachment_file_obj.file_name = attachment_file.file_name;
        this.attachment_file_obj.ac_id = attachment_file.ac_id;
        //render the audio file with old URL first then renew the URL
        let renewed_url = await this.renewMusicOnHoldSignedURL(
          attachment_file.file_path
        );
        this.attachment_file_obj.file_url = _.isEmpty(renewed_url)
          ? attachment_file.file_url
          : renewed_url;
      }
      this.isLoadingAttachment = false;
    },

    handleError() {
      this.isLoading = false;
      this.$message({
        message: __("Failed to upload the file"),
        type: "error"
      });
    },
    generateUniqueCode() {
      return "file" + Date.now() + Math.random();
    },
    handleSuccess(url) {
      this.isLoading = false;
      this.newFileUploaded = true;
      this.attachment_file_obj.file_path = url.path;
      this.attachment_file_obj.file_url = url.url;
      this.attachment_file_obj.file_name = url.uploaded_file_name;
      this.attachment_file_obj.ac_id = this.selectedAccountId;
      this.attachment_file_obj.unique_code = this.generateUniqueCode();
      this.nodeToBind.bridge_transfer_node.data.music_on_hold_file_id = -1;
    },

    handleCancel() {
      this.removeUploadedFiles();
      this.$emit("cancel");
    },
    handleDelete() {
      this.resetForm();
    },
    handleCancelAniExpressionBuilder() {
      this.displayAniExpressionBuilderModal = false;
    },
    handleCancelTransferNumberExpressionBuilder() {
      this.displayTransferPhoneNumberExpressionBuilderModal = false;
    },
    handleCancelDisplayNameExpressionBuilder() {
      this.displayNameExpressionBuilderModal = false;
    },
    resetForm() {
      this.removeUploadedFiles();
      this.attachment_file_obj = _.cloneDeep(attachmentFileObjInitialize);
    },
    removeUploadedFiles() {
      if (!_.isEmpty(this.attachment_file_obj.file_path)) {
        removeFiles(this.attachment_file_obj.file_path);
        this.deleteAttachmentData(this.attachment_file_obj);
        this.nodeToBind.bridge_transfer_node.data.music_on_hold_file_id = null;
      }
    },
    addOrRemoveMusicOnHoldFileId(fileObj) {
      if (fileObj.file_id > 0) {
        this.nodeToBind.bridge_transfer_node.data.music_on_hold_file_id =
          fileObj.file_id;
      } else {
        this.nodeToBind.bridge_transfer_node.data.music_on_hold_file_id = null;
      }
    },
    deleteAttachmentData(fileObj) {
      if (this.attachment_file_obj.file_id > 0) {
        this.isLoadingAttachment = true;
        this.deleteFile(fileObj)
          .then(() => {
            this.isLoadingAttachment = false;
            this.$message.success(
              __("Music on-hold audio removed successfully")
            );
          })
          .catch(err => {
            console.log(err);
            this.isLoadingAttachment = false;
            this.$message.warning(__("No such file to remove"));
          });
      }
    },
    createOrUpdateAttachmentFile(attachmentFileObj) {
      if (attachmentFileObj.file_path.length > 0 && this.newFileUploaded) {
        return new Promise((resolve, reject) => {
          this[this.getFileAttachmentAPIMethod](attachmentFileObj)
            .then(res => {
              this.attachment_file_obj = res.data;
              this.nodeToBind.bridge_transfer_node.data.music_on_hold_file_id =
                res.data.file_id;
              if (!this.isNodeSubmit) {
                this.showModal = true;
              }
              resolve();
            })
            .catch(err => {
              console.log(err);
              this.requestingDataStoreData = false;
              reject();
              this.$notify({
                message: __("Failed to upload the file"),
                type: "error"
              });
            });
        });
      }
      return Promise.resolve([]);
    },
    removeAttachmentFileFromNodesObject() {
      if (
        _.has(this.nodeToBind.bridge_transfer_node.data, "attachment_file.data")
      ) {
        delete this.nodeToBind.bridge_transfer_node.data["attachment_file"];
      }
    },

    cleanUpNodeToPrepareForSubmit() {
      // do necessary operations on a cloned version of nodeToBind obj
      // that return the nodeToBind object
      // in exactly the same way as an node object of this
      // type is returned from the backend

      // as I do not require any cleanup to do here in this particular case,
      // I am just sending back a cloned version of nodeToBind obj
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      // the manipulation to clean up the node
      // is moved to the above method. So we can
      // reassign the nodeToBind object as the object
      // that is returned from the cleanUpNodeToPrepareForSubmit method
      this.$refs.nodeForm.validate((valid, errors) => {
        if (valid) {
          this.createOrUpdateAttachmentFile(this.attachment_file_obj)
            .then(() => {
              this.addOrRemoveMusicOnHoldFileId(this.attachment_file_obj);
              this.removeAttachmentFileFromNodesObject();
              this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
              this.createOrEditNode();
            })
            .catch(() => {
              this.toggleNodeSubmit(false);
              this.$notify({
                message: __("Failed to save changes"),
                type: "error"
              });
            });
        } else {
          this.showErrorMessages(errors);
          this.toggleNodeSubmit(false);
          return false;
        }
      });
    },

    setNoAnswerBusyEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.bridge_transfer_node.data,
        "no_answer_busy_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "bridge_transfer_node.data.no_answer_busy_canvas_id"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/node_common.scss";
</style>
