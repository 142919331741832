<script>
import { renewAudioUrl } from "@/api/storage";
export default {
  name: "RenewSignedURL",
  methods: {
    renewMusicOnHoldSignedURL(file_path) {
      return renewAudioUrl(file_path)
        .then(response => {
          return response.data.renewed_url;
        })
        .catch(() => {
          this.$message.error(__("Failed to renew the signed URL"));
          return "";
        });
    }
  }
};
</script>
